'use client';

import type { Dispatch, ReactNode } from 'react';
import { createContext, useContext, useReducer } from 'react';
import type {
  ChildOrder,
  ShippingMethod,
  TAddOn,
  TCustomerBillingAddress,
  TSizingKitAddress,
  TSizingKitOption,
} from 'types/order-portal-api';

export type TFormValues = {
  clientId?: string;
  billingEmails?: string[];
  buyerEmails?: string[];
  billingAddressUS?: TCustomerBillingAddress;
  billingAddressINT?: TCustomerBillingAddress;
  giftNote?: { message?: string; sender?: string };
  membershipOption?: string;
  membershipTitle?: string;
  sizingKitsAddresses?: TSizingKitAddress[];
  sizingKitOption?: TSizingKitOption;
  ringShippingMethods?: ShippingMethod[];
  sizingKitShippingMethods?: ShippingMethod[];
  addOns?: {
    saasOptions?: TAddOn[];
    customerSupportOptions?: TAddOn[];
  };
  totalRingQuantity?: number;
  childOrders?: ChildOrder[];
  acceptedTermsAt?: string;
  bulkOrderStatus?: string;
};

export type TFormActionType =
  | {
      type: 'SAVE_FORM';
      payload: TFormValues;
    }
  | {
      type: 'RESET_FORM';
    };

interface IFormWizard {
  data: TFormValues;
}

interface IFormWizardContext extends IFormWizard {
  formDispatch: Dispatch<TFormActionType>;
}

const FormWizardContext = createContext<IFormWizardContext>(
  {} as IFormWizardContext,
);

const initialState: IFormWizard = {
  data: {},
};

export const reducer = (state: IFormWizard, action: TFormActionType) => {
  // NOTE: if additional actions are needed, convert to switch statement
  if (action.type === 'RESET_FORM') {
    return {
      ...state,
      data: {
        clientId: state.data.clientId,
        billingEmails: state.data.billingEmails,
        buyerEmails: state.data.buyerEmails,
      },
    };
  } else {
    return {
      ...state,
      data: {
        ...state.data,
        ...action.payload,
      },
    };
  }
};

const FormWizardProvider = ({ children }: { readonly children: ReactNode }) => {
  const [formState, formDispatch] = useReducer(reducer, initialState);

  return (
    <FormWizardContext.Provider
      value={{
        ...formState,
        formDispatch,
      }}
    >
      {children}
    </FormWizardContext.Provider>
  );
};

export function useFormWizard() {
  return useContext(FormWizardContext);
}

export default FormWizardProvider;
